<template>
  <div>
    <div>
      <b-card-actions title="Filters" action-collapse>
        <div>
          <b-row class="mb-1">
            <b-col md="6" lg="6" xs="12">
              <b-form-input
                  placeholder="Search By Project Title/Milestone/Expense..."
                  type="text"
                  class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
                  v-on:keyup="onSearch"
                  v-model.lazy="searchTerm"
              />
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <flat-pickr
                  v-model="filterRangeDate"
                  v-on:input="filterByTransactionDate"
                  class="form-control"
                  placeholder="Enter Transaction Date Range"
                  :config="{ mode: 'range'}"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" lg="3" xs="12">
              <v-select
                  v-model="filterTransactionType"
                  :options="transactionHistoryTypeConstants"
                  :reduce="(item) => item.name"
                  v-on:input="filterByTransactionType"
                  label="name"
                  placeholder="By Transaction Type"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
            <b-col md="6" lg="4" xs="12">
              <v-select
                  v-model="filterAccountId"
                  :options="filterAccountOption"
                  :reduce="(item) => item.id"
                  v-on:input="filterByAccount"
                  label="name"
                  placeholder="By Account"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="6" lg="5" xs="12">
              <v-select
                  v-model="filterDepartmentId"
                  :options="filterDepartmentOption"
                  :reduce="(item) => item.id"
                  v-on:input="filterByDepartment"
                  label="name"
                  placeholder="By Department"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
          </b-row>
        </div>
      </b-card-actions>
    </div>
    <div>
      <b-card>
        <div>
          <!-- search input -->
          <div
              class="custom-search d-flex align-items-center justify-content-end"
          >
            <div
                class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
            >
              <!-- <b-form-input
              v-on:keyup="onSearch"
              v-model.lazy="searchTerm"
              placeholder="Search..."
              type="text"
              class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
            /> -->
              <!-- <template v-if="$permissionAbility(DIVISION_CREATE, permissions)">
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Transfer Account
            </b-button>
          </template> -->
            </div>
          </div>

            <div
                class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-end"
            >

              <vue-excel-xlsx
                  class="border-0 bg-transparent"
                  :data="rows"
                  :columns="exportColumns"
                  :file-name="'Transaction History'"
                  :file-type="'xlsx'"
                  :sheet-name="'Transaction History'"
              >
                <b-button
                    class="flex-shrink-0 mr-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                  Export
                </b-button>
              </vue-excel-xlsx>


            </div>

              <vue-good-table
                  styleClass="vgt-table table-custom-style striped"
                  mode="remote"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  :totalRows="totalRecords"
                  :isLoading.sync="isLoading"
                  :rows="rows"
                  :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: [{ field: 'created_at', type: 'desc' }],
            }"
                  :columns="columns"
                  :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
              >
                <template slot="table-row" slot-scope="props">
                  <template v-if="props?.column?.field === 'format_account'">
                    <span>{{ props?.row?.account?.name }}</span>
                    <div class="cell-with-description">
                      <div v-if="props?.row?.account?.email">
                    <span>
                      Email: <b>{{ props?.row?.account?.email }}</b>
                    </span>
                      </div>
                      <div v-if="props?.row?.account?.account_number">
                    <span>
                      Acc No: <b>{{ props?.row?.account?.account_number }}</b>
                    </span>
                      </div>
                      <div v-if="props?.row?.account?.account_holder_name">
                    <span>
                      Holder Name: <b>{{ props?.row?.account?.account_holder_name }}</b>
                    </span>
                      </div>
                      <div v-if="props?.row?.previous_balance">
                        Previous Balance: <FormatCurrency :amount="props.row?.previous_balance" :currency="props?.row?.previous_currency" />
                      </div>
                      <div v-if="props?.row?.account_balance">
                    <span>
                       Balance: <FormatCurrency :amount="props.row?.account_balance" :currency="props?.row?.currency" />
                    </span>
                      </div>
                      <div>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            v-on:click="showRemarksModal(props?.row?.remarks)"
                            class="btn-sm"
                        >
                          <feather-icon icon="InfoIcon" />
                        </b-button>
                      </div>
                    </div>
                  </template>
                  <!-- Source -->
                  <template v-if="props?.column?.field === 'format_source'">
                    <template v-if="props?.row?.milestone">
                      <b-badge pill variant="light-primary">Project</b-badge>
                      <div class="cell-with-description">
                    <span>
                      Project: <b>{{ props?.row?.project?.lead?.title }}</b></span
                    >

                        <div v-if="props?.row?.milestone">
                      <span>
                        Milestone: <b>{{ props?.row?.milestone?.title }}</b></span
                      >
                        </div>
                        <div v-if="props?.row?.invoice">
                      <span>
                        Invoice : <b>{{ props?.row?.invoice?.invoice_no }}</b></span
                      >
                        </div>
                      </div>
                    </template>
                    <template v-if="props?.row?.expense">
                      <b-badge pill variant="light-primary">Expense</b-badge>
                      <div class="cell-with-description">
                        <span> Title: <b>{{ props?.row?.expense?.title }}</b></span>

                        <div v-if="props?.row?.department">
                      <span>
                        Department: <b>{{ props?.row?.department?.name }}</b
                      ></span
                      >
                        </div>
                        <div v-if="props?.row?.project">
                      <span>
                        Project: <b>{{ props?.row?.project?.lead?.title }}</b
                      ></span
                      >
                        </div>
                      </div>
                    </template>
                    <template v-if="props?.row?.account_deposit_id">
                      <b-badge pill variant="light-primary"
                      >Account Deposit</b-badge
                      >
                    </template>
                    <template v-if="props?.row?.account_transfer_id">
                      <b-badge pill variant="light-primary"
                      >Account Transfer</b-badge
                      >
                    </template>
                  </template>
                  <!-- format_payment_type -->
                  <template v-if="props?.column?.field === 'format_payment_type'">
                    <template v-if="props?.row?.payment_type == 'credit'">
                      <b-badge pill variant="light-success">Credit</b-badge>
                    </template>
                    <template v-else>
                      <b-badge pill variant="light-danger">Debit</b-badge>
                    </template>
                  </template>
                  <!-- format_amount -->
                  <template v-if="props?.column?.field === 'format_amount'">
                    <FormatCurrency :amount="props.row?.amount" :currency="props?.row?.currency" />
                  </template>

                  <template v-if="props?.column?.field === 'format_currency'">
                    <FormatCurrency :type="'currency'" :amount="props.row?.amount" :currency="props?.row?.currency" />
                  </template>

                  <!-- Column: Common -->
                  <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Showing 1 to </span>
                      <b-form-select
                          v-model="pageLength"
                          :options="['50', '100', '200']"
                          class="mx-1"
                          @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                      />
                      <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                    </div>
                    <div>
                      <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>

          <b-modal
              id="remarks-modal"
              centered
              title="Remarks"
              hide-footer
              @hidden="hiddenRemarksModal"
              no-close-on-backdrop
          >
            <b-card-text>
              {{ remarks }}
            </b-card-text>
          </b-modal>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BFormTextarea,
  BSpinner,
  VBTooltip,
  BRow,
  BCol,
  BFormDatepicker,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import flatPickr from 'vue-flatpickr-component'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { transactionHistoryTypeConstants } from "@/helpers/constant/transactionHistoryTypeConstant";
import {
  ACCOUNT_DEPOSIT,
  MILESTONE,
  ACCOUNT_TRANSFER,
  EXPENSE,
} from "@/helpers/constant/transactionHistoryModelTypeConstant";

import {
  DIVISION_CREATE,
  DIVISION_EDIT,
  DIVISION_DELETE,
} from "@/helpers/permissionsConstant";
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "TransactionHistoryView",
  components: {
    FormatCurrency,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTextarea,
    BRow,
    BCol,
    BFormDatepicker,
    BCardActions,
    flatPickr
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      DIVISION_CREATE,
      DIVISION_EDIT,
      DIVISION_DELETE,
      ACCOUNT_DEPOSIT,
      MILESTONE,
      ACCOUNT_TRANSFER,
      EXPENSE,
      filterRangeDate: "",
      modelType: "",
      allAccounts: "",
      fromAccountId: "",
      fromAccountIdOption: [],
      fromAccountAmount: "",
      toAccountId: "",
      toAccountIdOption: "",
      toAccountAmount: "",
      amount: "",
      remarks: "",
      pageLength: 50,
      transactionHistoryTypeConstants,
      filterStartDate: "",
      filterEndDate: "",
      filterTransactionType: "",
      filterAccountId: "",
      filterAccountOption: [],
      filterDepartmentId: "",
      filterDepartmentOption: [],

      columns: [
        {
          label: "Transaction Date",
          field: "created_at",
        },
        {
          label: "Account Info",
          field: "format_account",
          sortable: false,
        },
        {
          label: "Source Info",
          field: "format_source",
          sortable: false,
        },

        {
          label: "Type",
          field: "format_payment_type",
          sortable: false,
        },
        {
          label: "Currency",
          field: "format_currency",
        },
        {
          label: "Amount",
          field: "format_amount",
          sortable: false,
        },


        // {
        //   label: "Attachment",
        //   field: "format_attachment",
        //   sortable: false,
        // },
        // {
        //   label: "Remarks",
        //   field: "format_remarks",
        //   sortable: false,
        // },
      ],

      exportColumns: [
        {
          label: "Transaction Date",
          field: "created_at",
        },
        {
          label: "Account Info",
          field: "account.name",
        },
        {
          label: "Account Email",
          field: "account.email"
        },
        {
          label: "Currency",
          field: "account.currency"
        },
        {
          label: "Account Balance",
          field: "account.balance"
        },
        {
          label: "Account Previous Balance",
          field: "account.previous_balance"
        },
        {
          label: "Remarks",
          field: "remarks",
          sortable: false,
        },
        {
          label: "Project",
          field: "project.lead.title"
        },
        {
          label: "Milestone",
          field: "milestone.title"
        },
        {
          label: "Invoice",
          field: "invoice.invoice_no"
        },
        {
          label: "Type",
          field: "payment_type"
        },
        {
          label: "Amount",
          field: "amount"
        }
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      loading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      // this.loadItems();
      const [accounts, departments] = await Promise.all([
        this.getAccountsInfo(),
        this.getDepartments(),
      ]);

      // account types
      this.filterAccountOption = (accounts?.data?.data || []).map((item) => {
        let name = item.name;

        return {
          name,
          id: item.id,
        };
      });
      this.filterDepartmentOption = (departments?.data?.data || []).map(
          (item) => {
            let name = item.name;

            return {
              name,
              id: item.id,
            };
          }
      );
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error ",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    handleDateRangeChange()
    {
      if (this.filterRangeDate && this.filterRangeDate !== '') {
        const dates = this.filterRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },

    async export() {
      try {
        const { data } = await this.$api.post(`/api/transaction-export`);
        window.location.href = data.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    showRemarksModal(remarks) {
      if (remarks) {
        this.remarks = remarks;
      } else {
        this.remarks = "N/A";
      }

      this.$bvModal.show("remarks-modal");
    },

    hiddenRemarksModal() {
      this.$bvModal.hide("remarks-modal");
    },

    formatAccountType(value) {
      return value?.data?.name;
    },
    formatStatus(value) {
      if (value) {
        return "Active";
      }

      return "Inactive";
    },
    showModal() {
      this.$bvModal.show("modal-account-tarnsfer-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-account-tarnsfer-form");
      this.resetModal();
    },
    resetModal() {
      this.accountId = "";
      this.fromAccountId = "";
      this.toAccountId = "";
      this.amount = "";
      this.remarks = "";
    },
    async getTransactionHistory(params) {
      return await this.$api.get("api/finance-transaction-histories", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          filterStartDate: params.filterStartDate,
          filterEndDate: params.filterEndDate,
          filterTransactionType: params.filterTransactionType,
          filterAccountId: params.filterAccountId,
          filterDepartmentId: params.filterDepartmentId,
        },
      });
    },
    filterByAccount(accountId) {
      this.filterAccountId = accountId;
      this.loadItems();
    },
    filterByDepartment(deptId) {
      this.filterDepartmentId = deptId;
      this.loadItems();
    },
    filterByTransactionDate() {
      this.handleDateRangeChange();
      this.loadItems();
    },
    filterByTransactionType(type) {
      try {
        this.filterTransactionType = type;
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getAccountsInfo() {
      return await this.$api.get("api/accounts/active/all");
    },
    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },
    async loadItems() {
      try {
        const [transactionHistories] = await Promise.all([
          this.getTransactionHistory({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate,
            filterTransactionType: this.filterTransactionType,
            filterAccountId: this.filterAccountId,
            filterDepartmentId: this.filterDepartmentId,
          }),
        ]);

        const data = transactionHistories?.data?.data;

        const meta = transactionHistories?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
  td:last-child {
    vertical-align: center !important;
    text-align: center !important;
  }
}
.cell-with-description,
.cell-with-description div {
  margin: 5px 0px;
}
</style>
