var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "placeholder": "Search By Project Title/Milestone/Expense...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Enter Transaction Date Range",
      "config": {
        mode: 'range'
      }
    },
    on: {
      "input": _vm.filterByTransactionDate
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.transactionHistoryTypeConstants,
      "reduce": function reduce(item) {
        return item.name;
      },
      "label": "name",
      "placeholder": "By Transaction Type"
    },
    on: {
      "input": _vm.filterByTransactionType
    },
    model: {
      value: _vm.filterTransactionType,
      callback: function callback($$v) {
        _vm.filterTransactionType = $$v;
      },
      expression: "filterTransactionType"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterAccountOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "By Account"
    },
    on: {
      "input": _vm.filterByAccount
    },
    model: {
      value: _vm.filterAccountId,
      callback: function callback($$v) {
        _vm.filterAccountId = $$v;
      },
      expression: "filterAccountId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "5",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.filterDepartmentOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "By Department"
    },
    on: {
      "input": _vm.filterByDepartment
    },
    model: {
      value: _vm.filterDepartmentId,
      callback: function callback($$v) {
        _vm.filterDepartmentId = $$v;
      },
      expression: "filterDepartmentId"
    }
  })], 1)], 1)], 1)])], 1), _c('div', [_c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  })]), _c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-end"
  }, [_c('vue-excel-xlsx', {
    staticClass: "border-0 bg-transparent",
    attrs: {
      "data": _vm.rows,
      "columns": _vm.exportColumns,
      "file-name": 'Transaction History',
      "file-type": 'xlsx',
      "sheet-name": 'Transaction History'
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Export ")])], 1)], 1), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row$account, _props$row2, _props$row2$account, _props$row3, _props$row3$account, _props$row4, _props$row4$account, _props$row5, _props$row5$account, _props$row6, _props$row6$account, _props$row7, _props$row7$account, _props$row8, _props$row9, _props$row10, _props$row11, _props$row12, _props$row13, _props$column2, _props$row15, _props$row16, _props$row16$project, _props$row16$project$, _props$row17, _props$row18, _props$row18$mileston, _props$row19, _props$row20, _props$row20$invoice, _props$row21, _props$row22, _props$row22$expense, _props$row23, _props$row24, _props$row24$departme, _props$row25, _props$row26, _props$row26$project, _props$row26$project$, _props$row27, _props$row28, _props$column3, _props$row29, _props$column4, _props$row30, _props$row31, _props$column5, _props$row32, _props$row33;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_account' ? [_c('span', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$account = _props$row.account) === null || _props$row$account === void 0 ? void 0 : _props$row$account.name))]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row2 = props.row) !== null && _props$row2 !== void 0 && (_props$row2$account = _props$row2.account) !== null && _props$row2$account !== void 0 && _props$row2$account.email ? _c('div', [_c('span', [_vm._v(" Email: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : (_props$row3$account = _props$row3.account) === null || _props$row3$account === void 0 ? void 0 : _props$row3$account.email))])])]) : _vm._e(), props !== null && props !== void 0 && (_props$row4 = props.row) !== null && _props$row4 !== void 0 && (_props$row4$account = _props$row4.account) !== null && _props$row4$account !== void 0 && _props$row4$account.account_number ? _c('div', [_c('span', [_vm._v(" Acc No: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : (_props$row5$account = _props$row5.account) === null || _props$row5$account === void 0 ? void 0 : _props$row5$account.account_number))])])]) : _vm._e(), props !== null && props !== void 0 && (_props$row6 = props.row) !== null && _props$row6 !== void 0 && (_props$row6$account = _props$row6.account) !== null && _props$row6$account !== void 0 && _props$row6$account.account_holder_name ? _c('div', [_c('span', [_vm._v(" Holder Name: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : (_props$row7$account = _props$row7.account) === null || _props$row7$account === void 0 ? void 0 : _props$row7$account.account_holder_name))])])]) : _vm._e(), props !== null && props !== void 0 && (_props$row8 = props.row) !== null && _props$row8 !== void 0 && _props$row8.previous_balance ? _c('div', [_vm._v(" Previous Balance: "), _c('FormatCurrency', {
          attrs: {
            "amount": (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.previous_balance,
            "currency": props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.previous_currency
          }
        })], 1) : _vm._e(), props !== null && props !== void 0 && (_props$row11 = props.row) !== null && _props$row11 !== void 0 && _props$row11.account_balance ? _c('div', [_c('span', [_vm._v(" Balance: "), _c('FormatCurrency', {
          attrs: {
            "amount": (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.account_balance,
            "currency": props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.currency
          }
        })], 1)]) : _vm._e(), _c('div', [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-sm",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row14;
              return _vm.showRemarksModal(props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.remarks);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)], 1)])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_source' ? [props !== null && props !== void 0 && (_props$row15 = props.row) !== null && _props$row15 !== void 0 && _props$row15.milestone ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v("Project")]), _c('div', {
          staticClass: "cell-with-description"
        }, [_c('span', [_vm._v(" Project: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : (_props$row16$project = _props$row16.project) === null || _props$row16$project === void 0 ? void 0 : (_props$row16$project$ = _props$row16$project.lead) === null || _props$row16$project$ === void 0 ? void 0 : _props$row16$project$.title))])]), props !== null && props !== void 0 && (_props$row17 = props.row) !== null && _props$row17 !== void 0 && _props$row17.milestone ? _c('div', [_c('span', [_vm._v(" Milestone: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : (_props$row18$mileston = _props$row18.milestone) === null || _props$row18$mileston === void 0 ? void 0 : _props$row18$mileston.title))])])]) : _vm._e(), props !== null && props !== void 0 && (_props$row19 = props.row) !== null && _props$row19 !== void 0 && _props$row19.invoice ? _c('div', [_c('span', [_vm._v(" Invoice : "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : (_props$row20$invoice = _props$row20.invoice) === null || _props$row20$invoice === void 0 ? void 0 : _props$row20$invoice.invoice_no))])])]) : _vm._e()])] : _vm._e(), props !== null && props !== void 0 && (_props$row21 = props.row) !== null && _props$row21 !== void 0 && _props$row21.expense ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v("Expense")]), _c('div', {
          staticClass: "cell-with-description"
        }, [_c('span', [_vm._v(" Title: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row22 = props.row) === null || _props$row22 === void 0 ? void 0 : (_props$row22$expense = _props$row22.expense) === null || _props$row22$expense === void 0 ? void 0 : _props$row22$expense.title))])]), props !== null && props !== void 0 && (_props$row23 = props.row) !== null && _props$row23 !== void 0 && _props$row23.department ? _c('div', [_c('span', [_vm._v(" Department: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row24 = props.row) === null || _props$row24 === void 0 ? void 0 : (_props$row24$departme = _props$row24.department) === null || _props$row24$departme === void 0 ? void 0 : _props$row24$departme.name))])])]) : _vm._e(), props !== null && props !== void 0 && (_props$row25 = props.row) !== null && _props$row25 !== void 0 && _props$row25.project ? _c('div', [_c('span', [_vm._v(" Project: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row26 = props.row) === null || _props$row26 === void 0 ? void 0 : (_props$row26$project = _props$row26.project) === null || _props$row26$project === void 0 ? void 0 : (_props$row26$project$ = _props$row26$project.lead) === null || _props$row26$project$ === void 0 ? void 0 : _props$row26$project$.title))])])]) : _vm._e()])] : _vm._e(), props !== null && props !== void 0 && (_props$row27 = props.row) !== null && _props$row27 !== void 0 && _props$row27.account_deposit_id ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v("Account Deposit")])] : _vm._e(), props !== null && props !== void 0 && (_props$row28 = props.row) !== null && _props$row28 !== void 0 && _props$row28.account_transfer_id ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v("Account Transfer")])] : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_payment_type' ? [(props === null || props === void 0 ? void 0 : (_props$row29 = props.row) === null || _props$row29 === void 0 ? void 0 : _props$row29.payment_type) == 'credit' ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-success"
          }
        }, [_vm._v("Credit")])] : [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("Debit")])]] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_amount' ? [_c('FormatCurrency', {
          attrs: {
            "amount": (_props$row30 = props.row) === null || _props$row30 === void 0 ? void 0 : _props$row30.amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row31 = props.row) === null || _props$row31 === void 0 ? void 0 : _props$row31.currency
          }
        })] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_currency' ? [_c('FormatCurrency', {
          attrs: {
            "type": 'currency',
            "amount": (_props$row32 = props.row) === null || _props$row32 === void 0 ? void 0 : _props$row32.amount,
            "currency": props === null || props === void 0 ? void 0 : (_props$row33 = props.row) === null || _props$row33 === void 0 ? void 0 : _props$row33.currency
          }
        })] : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['50', '100', '200']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "remarks-modal",
      "centered": "",
      "title": "Remarks",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenRemarksModal
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.remarks) + " ")])], 1)], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }